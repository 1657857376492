// Import your CSS/SCSS files her
@import url("https://fonts.cdnfonts.com/css/calibri-light");
@import url("https://db.onlinewebfonts.com/c/527c5ab608cab860a6aae8ce02e14b0e?family=Calibri+Bold");
body {
  background: #f8f8f8;
  font-family: "Calibri Light", sans-serif;
  color: #303c28;
}
.page-content {
  padding-left: 400px;
  position: relative;
  @media (max-width: 1300px) {
    padding-left: 320px;
  }
  @media (max-width: 1100px) {
    padding-left: 220px;
  }
  @media (max-width: 1000px) {
    padding-left: 0px;
  }
}
.mob-logo {
  display: none;
  position: absolute;
  left: 2.5rem;
  top: 1.5rem;
  @media (max-width: 1000px) {
    display: block;
  }
}
.menu-btn {
  display: none;
  @media (max-width: 1000px) {
    display: inline-block;
  }
}
.mobile-menu {
  transform: translateX(-100%);
  display: none;
  @media (max-width: 1000px) {
    display: block;
  }
}
.mobile-menu.open {
  transform: translateX(0%);
}
.page-header {
  height: 100vh;
  width: 387px;
  position: absolute;
  left: 0;
  top: 0;
  @media (max-width: 1300px) {
    width: 300px;
  }
  @media (max-width: 1100px) {
    width: 200px;
  }
  @media (max-width: 1000px) {
    display: none;
  }
}
h1,
h2 {
  font-family: 'Tinos', serif;
  color: #294128;
}

.nav-title {
  font-size: responsive 20px 35px !important;
  line-height: responsive 28px 35px !important;
  font-family: 'Tinos', serif;
}
p {
  margin-bottom: 1rem;
  font-size: 15px;
}
h2,
.h2 {
  font-size: responsive 20px 35px !important;
  line-height: responsive 28px 35px !important;
  font-weight: 400;
}

.h3, h3 {
  font-size: 20px;
  font-family: 'Tinos', serif;
  color: #294128;
}
h4 {
  font-size: 16px;
  font-family: 'Tinos', serif;
  color: #294128;
  font-weight: 700;
}
.legislation {
  h4 {
    margin-bottom: 1.5rem;
    font-family: 'Tinos', serif;
  }
  a{
    color: #294128;
  }
  ul {
    margin-left: 1rem;

    li {
      list-style-type: initial;
      margin-bottom: 0.5rem;
    }
  }
}
.green-pattern {
  background-image: url("images/green-pattern-bg.png");
  p {
    font-size: responsive 20px 35px !important;
    line-height: responsive 28px 41px !important;
    font-family: 'Tinos', serif;
    color: white;
  }
}

summary:after {
  content: "+";
}

details[open] summary:after {
  content: "-";
}

details[open] summary {
  content: "-";
  background-color: #dfdfdf;
}

details {
  margin-top: 5px;
  margin-bottom: 5px;
}

details[open] {
  background-color: white;
  padding-bottom: 40px;
}

summary[open] {
  background-color: white;
  padding-bottom: 40px;
}

summary {
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

summary:hover {
  cursor: pointer !important;
  transition: ease-in-out 300ms;
}

details > summary::-webkit-details-marker {
  display: none;
}

.text-col, .content {
  h2 {
    padding-bottom: 2rem;
  }
  h3 {
    padding-bottom: 2rem;
  }
  a{
    color: #294128;
  }
  ul {
    margin-left: 1rem;

    li {
      list-style-type: initial;
      margin-bottom: 0.5rem;
    }
  }
}
.description{
  margin-top: 2rem;
  table{
    border: 1px solid #6C8E58;
    thead{
      background-color: #6C8E58;
      color: white;
      text-align: left;
      font-weight: bold;
      font-family: "Calibri Bold";
      th{
        padding: 10px 10px;
        border-left: 1px solid white;
      }
    }
    tbody{
      tr{
        td{
          padding: 10px;
          vertical-align: top;
          border-left: 1px solid #294128;
          border-bottom: 1px solid #294128;
        }
      }
    }
  }
}